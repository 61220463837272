import { useState, useContext, useEffect } from "react";
import { DynamoConfigContext, DynamoEnvKeys } from "src/context/configContext";

interface PartnerLogo {
  url: string;
  height: number;
  width: number;
}

export const usePartnerLogos = (): PartnerLogo[] => {
  const [logos, setLogos] = useState<PartnerLogo[]>([]);

  const configContext = useContext(DynamoConfigContext);

  useEffect(() => {
    const config = configContext?.config;
    const logos = config?.find(
      (configEle) => configEle.key.S === DynamoEnvKeys.DEV_LANDING_MARQUEE,
    );

    // Parse the entry or parse an empty array
    const parsedLogos = JSON.parse(logos?.value.S ?? "[]");
    setLogos(parsedLogos);
  }, [configContext]);

  return logos;
};